
import GeneralData from "@/modules/purchaseInvoices/components/GeneralData.vue";
import Items from "@/modules/purchaseInvoices/components/Items.vue";
import usePurchaseInvoice from "@/modules/purchaseInvoices/composables/usePurchaseInvoice";
import toastr from "toastr";
import {onMounted, Ref, ref} from "vue";
import router from "@/router";
import Loading from "@/components/Loading.vue";
import {PurchaseInvoiceCatalogs} from "@/modules/purchaseInvoices/interfaces";

export default {
    components: {Loading, GeneralData, Items},
    setup() {
        const {clearForm, purchaseInvoiceForm, createPurchaseInvoice, getCatalogs} = usePurchaseInvoice()
        const sending = ref(false)
        const loading = ref(true)
        const catalogs: Ref<PurchaseInvoiceCatalogs | null> = ref(null)

        clearForm();
        onMounted(async () => {
            catalogs.value = await getCatalogs()
            loading.value = false
        });

        return {
            mode: 'CREATE',
            sending,
            loading,
            catalogs,
            cancel: () => router.push({name: 'purchase_invoices'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await createPurchaseInvoice(purchaseInvoiceForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'purchase_invoices'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
